import React, { useContext } from 'react';
import { Loader } from "@fluentui/react-northstar";
import AppContext from '../contexts/AppContext';
import Authenticate from '../components/authenticate/Authenticate';

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
// eslint-disable-next-line react/prop-types
const AuthenticatedRoute: React.FC = ({ children}) => {
  const { currentUser } = useContext(AppContext);

  return currentUser ? (<>
    { (currentUser.aadUserId && currentUser.teamviewerAccount?.userId) ? children : (<Authenticate />) }
  </>) : (
    <Loader />
  ); 
};

export default AuthenticatedRoute;
