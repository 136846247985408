import React, { useState } from "react";
import { Alert, Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY
  }
});
appInsights.loadAppInsights();

// render
const Authenticate = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();

  function signup() {
    microsoftTeams.executeDeepLink('https://login.teamviewer.com/LogOn#register');
  }

  function authenticate() {
    setLoading(true);
    setErrorMessage(undefined);

    // Ensure logout
    fetch("/silentlogout", {
        credentials: "same-origin",
        cache: "no-cache",
    }).then(() => console.log('silentlogout'));

    microsoftTeams.getContext((teamsCtxt) => {
      const curProps = {
        'aaduid': teamsCtxt.userObjectId || '',
        'tid': teamsCtxt.tid || '',
      };

      appInsights.trackEvent({
        name: 'login_start',
        properties: curProps
      });
      appInsights.startTrackEvent('login_process');
      microsoftTeams.authentication.authenticate({
        url: window.location.origin + `/auth?tid=${teamsCtxt.tid}&uid=${teamsCtxt.userObjectId}`,
        width: 800,
        height: 650,
        successCallback: function (result: any) {
          appInsights.stopTrackEvent('login_process', { ...curProps, status: 'success' });
          appInsights.trackEvent({
            name: 'login_success',
            properties: curProps
          });
          appInsights.flush(false);
          // reload page
          document.location.assign(document.location.href); // Try to avoid ?
        },
        failureCallback: function (reason: any) {
          appInsights.stopTrackEvent('login_process', { ...curProps, status: 'login_failure' });
          appInsights.trackEvent({
            name: 'login_failure',
            properties: curProps
          })
          appInsights.flush(false);
          setLoading(false);

          setErrorMessage(t("Authenticate.ErrorMessage"));
        }
      });
    });
  }

  return (
    <Flex column hAlign="start" vAlign="start" gap={"gap.medium"}>
      <h1>{t("Authenticate.Title")}</h1>
      <Text content={t("Authenticate.Description")} />
      {!loading &&
        <Flex className="actions" hAlign="start" gap={"gap.large"}>
          <Button primary onClick={authenticate} content={t("Authenticate.ButtonLoginLabel")} />
          <Button primary onClick={signup} content={t("Authenticate.ButtonSignupLabel")} />
        </Flex>
      }
      {errorMessage &&
        <Alert danger content={errorMessage} styles={{ width: "100%", marginTop: 30 }} />
      }
      {loading &&
        <Loader />
      }
    </Flex>
  );
};

export default Authenticate;
