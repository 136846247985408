import React from 'react';
import '@microsoft/teams-js';
import UserInfoDto from '../models/UserInfoDto';

export interface IAppContext {
  currentUser: UserInfoDto | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<UserInfoDto | undefined>>;
  theme: string | undefined;
  setTheme: React.Dispatch<React.SetStateAction<string | undefined>>;
  teamsContext: microsoftTeams.Context | undefined;
  setTeamsContext: React.Dispatch<React.SetStateAction<microsoftTeams.Context | undefined>>;
}

const AppContext: React.Context<IAppContext> = React.createContext({
  currentUser: undefined,
  setCurrentUser: () => undefined,
  theme: undefined,
  setTheme: () => undefined,
  teamsContext: undefined,
  setTeamsContext: () => undefined,
} as IAppContext);

export const AppContextProvider = AppContext.Provider;

export default AppContext;
