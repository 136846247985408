import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Loader,
  Flex,
  Alert,
  FormCheckbox,
  FlexItem,
} from "@fluentui/react-northstar"; // cf. https://fluentsite.z22.web.core.windows.net/0.62.0
import { useTranslation } from "react-i18next";
import AppContext from "../contexts/AppContext";
import { TeamViewerApi } from "../api/TeamViewerApi";
import TopBar from "../components/topBar/TopBar";

const enableNotificationsLabelId = "form-enable-notifications";

const TabSettings = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [changingNotificationsEnabled, setChangingNotificationsEnabled] = useState<boolean>(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>();
  const { currentUser, teamsContext } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    if (teamsContext && currentUser?.aadUserId && !initialized) {
      // Call API to get devices
      TeamViewerApi.getNotificationsStatus(currentUser.aadUserId)
        .then((respStatus) => {
          setNotificationsEnabled(respStatus.data.enabled);
          setInitialized(true);
        })
        .catch(() => {
          setErrorMessage(t("TabSettings.Errors.CannotGetNotificationStatus"));
        });
    }
  }, [teamsContext, initialized, currentUser, t]);

  const handleNotificationsEnable = useCallback((enabled: boolean | undefined) => {
    if (enabled !== undefined && currentUser?.aadUserId) {
      setErrorMessage(undefined); // reset error message if one
      setChangingNotificationsEnabled(true); // set loader / disable to avoid multiple click
      TeamViewerApi.updateNotificationsStatus(currentUser.aadUserId, enabled)
        .then((respStatus) => {
          setNotificationsEnabled(enabled);
        })
        .catch(() => {
          setErrorMessage(t("TabSettings.Errors.CannotUpdateNotificationsStatus"));
        })
        .finally(() => {
          setChangingNotificationsEnabled(false);
        });
    }
  }, [currentUser, t]);

  return (<Flex column>
    <TopBar title={t("TabSettings.Title")} />
    <FlexItem grow={2}>
      <div className="tab-settings">
        {!initialized && <Loader label={t("App.Loading")} size={"large"} />}
        {initialized && (
          <Flex
            column
            gap="gap.medium"
            vAlign="start"
            hAlign="start">
            <FormCheckbox
              label={{
                content:
                  <Flex>{t("TabSettings.EnableNotificationsLabel")}{changingNotificationsEnabled && <Loader size="small" style={{ marginLeft: 10 }} />}</Flex>,
                id: enableNotificationsLabelId
              }}
              disabled={notificationsEnabled === undefined || changingNotificationsEnabled}
              aria-labelledby={enableNotificationsLabelId}
              toggle
              checked={notificationsEnabled}
              onChange={(ev, data) => {
                handleNotificationsEnable(data?.checked);
              }}
            />
            {errorMessage &&
              <Alert danger content={errorMessage} styles={{ width: "100%", marginTop: 30 }} />
            }
          </Flex>
        )}
      </div>
    </FlexItem>
  </Flex>
  );
};

export default TabSettings;
