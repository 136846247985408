import { useContext } from "react"; import {
    Flex,
    Dialog,
} from "@fluentui/react-northstar";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppContext from "../../contexts/AppContext";

export interface TopBarProps {
    title: string;
}

const TopBar = (props: TopBarProps) => {
    const { currentUser } = useContext(AppContext);
    const location = useLocation();
    const { t } = useTranslation();
    const { title } = props;

    const signOut = () => {
        document.location.assign(`/logout?redirectUrl=${encodeURIComponent(location.pathname)}&uid=${currentUser ? currentUser.aadUserId : ''}`);
    };

    return (<Flex gap="gap.medium" hAlign="stretch" space="between">
        <div><h1>{title}</h1></div>
        {currentUser && currentUser.teamviewerAccount && (
            <div style={{ textAlign: "right" }}>
                {currentUser.teamviewerAccount.email}
                <Dialog
                    cancelButton={t("Logout.Cancel")}
                    confirmButton={t("Logout.Confirm")}
                    onConfirm={signOut}
                    header={t("Logout.Title")}
                    content={t("Logout.Description")}
                    trigger={
                        <span
                            className="signout"
                            aria-label={t("App.ButtonSignoutLabel")}
                        >
                            <i
                                className="fas fa-power-off"
                                aria-label={t("App.ButtonSignoutLabel")}
                            />
                        </span>
                    }
                />
            </div>
        )}
    </Flex>
    );
}

export default TopBar;