import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Loader, Flex, Alert, FlexItem } from "@fluentui/react-northstar"; // cf. https://fluentsite.z22.web.core.windows.net/0.62.0
import { useTranslation } from "react-i18next";
import AppContext from "../contexts/AppContext";
import { TeamViewerApi } from "../api/TeamViewerApi";
import AlertDto from "../models/AlertDto";
import DeviceDto from "../models/DeviceDto";
import TopBar from "../components/topBar/TopBar";

const AlarmDetails = () => {
  const useParams = new URLSearchParams(document.location.search);
  const alarmId = useParams.get("alarmId");
  const deviceId = useParams.get("alarmId");
  const alarmType = useParams.get("alarmType");
  const [initialized, setInitialized] = useState<boolean>(false);
  const { currentUser, teamsContext } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [alert, setAlert] = useState<AlertDto>();
  const [device, setDevice] = useState<DeviceDto>();

  const { t } = useTranslation();

  useEffect(() => {
    if (teamsContext && currentUser?.aadUserId && !initialized) {
      // Call API to get details
      if (alarmId) {
        TeamViewerApi.getAlertById(alarmId).then((res) => {
          setAlert(res.data);
          setInitialized(true);
        });
        setDevice(undefined);
        /*if (deviceId) {
          TeamViewerApi.getDeviceInformation(deviceId).then((dev) => {
            setDevice(dev.data);
          });
        }*/
      } else {
        setErrorMessage(t("AlarmDetails.Errors.NotFound"));
        setInitialized(true);
      }
    }
  }, [teamsContext, initialized, currentUser, t, deviceId, alarmId]);

  return (<Flex column>
    <TopBar title={t("AlarmDetails.Title")} />
    <FlexItem grow={2}>
      <div className="alarm-details">
        {!initialized && <Loader label={t("App.Loading")} size={"large"} />}
        {initialized && (
          <Flex column gap="gap.medium" vAlign="start" hAlign="start">
            {alert && (<>
              <div>
                <b>{t("AlarmDetails.Type")}</b> : {t(`AlarmDetails.Types.${alert.type ?? alarmType}`)}
              </div>
              <div>
                <b>{t("AlarmDetails.Configuration")}</b> :
                {alert.alarmConfiguration ?
                  (<ul>
                    {Object.entries(JSON.parse(alert.alarmConfiguration)).map(
                      ([key, value]) => {
                        return (
                          <li key={key}>
                            <i>{key}</i> : {Number.isNaN(value) || Number.isInteger(value) ? (value as number).toFixed(2) : value}
                          </li>
                        );
                      }
                    )}
                  </ul>)
                  : " -"}
              </div>
              <div>
                <b>{t("AlarmDetails.Details")}</b> :
                {alert.alarmDetails ?
                  (<ul>
                    {Object.entries(JSON.parse(alert.alarmDetails)).map(
                      ([key, value]) => {
                        return (
                          <li key={key}>
                            <i>{key}</i> : {Number.isNaN(value) || Number.isInteger(value) ? (value as number).toFixed(2) : value}
                          </li>
                        );
                      }
                    )}
                  </ul>)
                  : " -"}
              </div></>
            )}
            {device && (<>
              <div>
                <b>{t("AlarmDetails.Computer")}</b>: {device.computer_name}
              </div>
              <div>
                <b>{t("AlarmDetails.Domain")}</b> : {device?.domain}
              </div>
            </>)}
            {errorMessage && (
              <Alert
                danger
                content={errorMessage}
                styles={{ width: "100%", marginTop: 30 }}
              />
            )}
          </Flex>
        )}
      </div>
    </FlexItem>
  </Flex>);
};

export default AlarmDetails;
