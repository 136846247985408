import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Loader,
  Flex,
  Alert,
  Input,
  InputProps,
  DropdownItemProps,
  FormDropdown,
  FormField,
  FormLabel,
  FlexItem,
} from "@fluentui/react-northstar"; // cf. https://fluentsite.z22.web.core.windows.net/0.62.0
import * as microsoftTeams from "@microsoft/teams-js";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import MonitoringDeviceDto from "../models/MonitoringDeviceDto";
import AppContext from "../contexts/AppContext";
import { ConnectorApi } from "../api/ConnectorApi";
import { TeamViewerApi } from "../api/TeamViewerApi";
import TopBar from "../components/topBar/TopBar";

const selectDeviceLabelId = "form-select-device";
const connectorNameHtmlForId = "form-connector-name";

const ConnectorConfig = () => {
  const useParams = new URLSearchParams(document.location.search);
  const currentEntityId = useParams.get('eid');
  const currentDeviceId = useParams.get('did');
  const currentUserId = useParams.get('uid');
  const currentConnectorName = useParams.get('cname');

  const [initialized, setInitialized] = useState<boolean>(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState<number>();;
  const [connectorName, setConnectorName] = useState<string | undefined>(currentConnectorName ? currentConnectorName : undefined);
  const [devices, setDevices] = useState<MonitoringDeviceDto[]>();
  const { currentUser, teamsContext } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    if (teamsContext && !initialized) {
      // Call API to get devices
      TeamViewerApi.getDevices()
        .then((respDevices) => {
          setInitialized(true);

          if (respDevices.data) {
            if (currentDeviceId) {
              const curSelectedDevice = respDevices.data.find((d) => d.teamviewerId.toString() === currentDeviceId);
              if (curSelectedDevice) {
                setSelectedDeviceId(curSelectedDevice.teamviewerId);
              }
            }
            setDevices(respDevices.data);
          }
          else {
            setDevices([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(t("ConnectorConfig.Errors.CannotRetrieveDevices"));
          setDevices([]);
          setInitialized(true);
        });
    }
  }, [teamsContext, currentDeviceId, initialized, t]);

  useEffect(() => {
    const onSave = (ev: microsoftTeams.settings.SaveEvent) => {
      if (!connectorName || !selectedDeviceId) {
        ev.notifyFailure();
      }
      else {
        // save settings, cf. https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/connectors-creating#add-a-connector-to-teams-app
        const isCreate = !currentEntityId;
        const entityId = isCreate ? uuidv4() : currentEntityId;
        microsoftTeams.settings.setSettings({
          suggestedDisplayName: connectorName,
          entityId: entityId,
          contentUrl: `${window.location.origin}/connector/config?eid=${entityId}&cname=${encodeURI(connectorName)}&uid=${teamsContext?.userObjectId}&did=${selectedDeviceId}`,
          configName: connectorName
        } as any);

        // if ok, then get settings to get the webhook url
        // get webhook + channel id + current user id + connector name + selected device etc
        microsoftTeams.settings.getSettings(async (settings) => {
          try {
            const entityData = {
              Name: connectorName,
              WebhookUrl: (settings as any).webhookUrl,
              AadUserId: currentUser?.aadUserId,
              SelectedDeviceId: selectedDeviceId,
              TeamId: teamsContext?.teamId,
              ChannelId: teamsContext?.channelId,
            };

            if (isCreate) {
              await ConnectorApi.CreateConnectorConfiguration(
                entityId,
                entityData);
            } else {
              await ConnectorApi.UpdateConnectorConfiguration(
                entityId,
                entityData);
            }
            ev.notifySuccess();
          }
          catch {
            ev.notifyFailure();
          }
        });
      }
    };

    const onRemove = (ev: microsoftTeams.settings.RemoveEvent) => {
      if (currentEntityId) {
        ConnectorApi.DeleteConnectorConfiguration(currentEntityId)
          .then(() => {
            ev.notifySuccess();
          }).catch(() => {
            ev.notifyFailure();
          })
      }
    };

    microsoftTeams.settings.registerOnSaveHandler(onSave);
    microsoftTeams.settings.registerOnRemoveHandler(onRemove);
    microsoftTeams.settings.setValidityState(selectedDeviceId !== undefined && (!currentUserId || currentUserId === teamsContext?.userObjectId));
  }, [selectedDeviceId, connectorName, currentUser, teamsContext, currentEntityId, currentUserId]);

  const handleNameChange = (
    ev: React.SyntheticEvent<HTMLElement, Event>,
    data: (InputProps & { value: string }) | undefined
  ) => {
    if (data?.value) setConnectorName(data.value);
    else setConnectorName(undefined);
  };
  const devicesAsDropdownItems = useMemo(() => {
    if (devices) {
      const items = devices.map((d) => {
        return { key: d.teamviewerId.toString(), header: d.alias, selected: d.teamviewerId === selectedDeviceId } as DropdownItemProps;
      });

      console.log(items);
      return items;
    }
    return [];
  }, [devices, selectedDeviceId]);

  return (<Flex column>
    <TopBar title={t("ConnectorConfig.Title")} />
    <FlexItem grow={2}>
      <div className="connector-config">
        {!initialized && <Loader label={t("App.Loading")} size={"large"} />}
        {initialized && (
          <Flex
            column
            gap="gap.medium"
            vAlign="start"
            hAlign="start"
          >
            <FormDropdown
              items={devicesAsDropdownItems}
              label={{
                content: t("ConnectorConfig.SelectDeviceLabel"),
                id: selectDeviceLabelId,
                required: true
              }}
              placeholder={t("ConnectorConfig.SelectDevicePlaceholder")}
              disabled={(!devices || devices.length === 0)}
              aria-labelledby={selectDeviceLabelId}
              checkable
              clearable
              aria-required={true}
              value={selectedDeviceId ? devicesAsDropdownItems.find((d) => (d as any).key === selectedDeviceId.toString()) : undefined}
              onChange={(ev, data) => {
                let curSelectedDevice: MonitoringDeviceDto | undefined;

                if (data.value) {
                  curSelectedDevice = devices?.find(
                    (s) => s.teamviewerId.toString() === (data as any).value.key
                  );
                }

                if (curSelectedDevice) {
                  setSelectedDeviceId(curSelectedDevice.teamviewerId);
                  setConnectorName(t("ConnectorConfig.DefaultConnectorName", { name: curSelectedDevice.alias }));
                } else {
                  setSelectedDeviceId(undefined);
                  setConnectorName('');
                }
              }}
            />
            <FormField styles={{ width: "100%" }}>
              <FormLabel
                htmlFor={connectorNameHtmlForId}
                required={true}
                content={t("ConnectorConfig.ConnectorNameLabel")} />
              <Input
                id={connectorNameHtmlForId}
                required={true}
                disabled={(!devices || devices.length === 0)}
                value={connectorName}
                maxLength={100}
                fluid
                onChange={handleNameChange}
              />
            </FormField>
            {errorMessage &&
              <Alert danger content={errorMessage} styles={{ width: "100%", marginTop: 30 }} />
            }
          </Flex>
        )}
      </div>
    </FlexItem>
  </Flex>
  );
};

export default ConnectorConfig;
