import { useCallback, useEffect, useMemo, useState } from "react";
import { Routes, Route } from "react-router-dom";
import {
  Flex,
  FlexItem,
  Provider,
  teamsDarkTheme,
  teamsTheme,
  teamsForcedColorsTheme,
} from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import AccountDto from "./models/AccountDto";
import UserInfoDto from "./models/UserInfoDto";
import ConnectorConfig from "./routes/ConnectorConfig";
import { TeamViewerApi } from "./api/TeamViewerApi";
import { AppContextProvider } from "./contexts/AppContext";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import "./sass/app.scss";
import TabSettings from "./routes/TabSettings";
import AlarmDetails from "./routes/AlarmDetails";

const App = () => {
  const [currentUser, setCurrentUser] = useState<UserInfoDto>();
  const [currentTvAccount, setCurrentTvAccount] = useState<AccountDto | undefined | null>();
  const [teamsContext, setTeamsContext] = useState<microsoftTeams.Context>();
  const [theme, setTheme] = useState<string>();

  const checkUserStatus = useCallback(
    (context: microsoftTeams.Context) => {
      // Get authenticated user
      setTeamsContext(context);
      setTheme(context.theme || "default");
      //i18n.changeLanguage(context.locale);
      microsoftTeams.appInitialization.notifySuccess();
    },
    [] // i18n
  );

  useEffect(() => {
    if (currentTvAccount === undefined) {
      TeamViewerApi.getUserAccount()
        .then((acc) => {
          setCurrentTvAccount(acc.data);
        })
        .catch(() => {
          // Not authenticated
          setCurrentTvAccount(null);
        });
    }
  }, [currentTvAccount]);

  useEffect(() => {
    if (teamsContext && currentTvAccount !== undefined) {
      setCurrentUser({
        aadUserId: teamsContext.userObjectId,
        teamviewerAccount: currentTvAccount ?? undefined,
      });
    }
  }, [teamsContext, currentTvAccount]);

  // Start loading
  useEffect(() => {
    if (!teamsContext) {
      microsoftTeams.initialize();
      microsoftTeams.registerOnThemeChangeHandler((theme: string) => {
        setTheme(theme);
      });
      microsoftTeams.appInitialization.notifyAppLoaded();
      microsoftTeams.getContext(checkUserStatus);
    }
  }, [teamsContext, checkUserStatus]); // i18n

  const currentTeamsTheme = useMemo(() => {
    if (theme === "dark") return teamsDarkTheme;
    else if (theme === "contrast") return teamsForcedColorsTheme;
    return teamsTheme;
  }, [theme]);

  return (
    <AppContextProvider
      value={{
        currentUser,
        setCurrentUser,
        theme,
        setTheme,
        teamsContext,
        setTeamsContext,
      }}
    >
      <Provider className={`app theme-${theme}`} theme={currentTeamsTheme}>
        <Flex column>
          <FlexItem grow={2}>
            <Routes>
              <Route
                path="/connector/config"
                element={
                  <AuthenticatedRoute>
                    <ConnectorConfig />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/tabs/settings"
                element={
                  <AuthenticatedRoute>
                    <TabSettings />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/tasks/alarmdetails"
                element={
                  <AuthenticatedRoute>
                    <AlarmDetails />
                  </AuthenticatedRoute>
                }
              />
            </Routes>
          </FlexItem>
        </Flex>
      </Provider>
    </AppContextProvider>
  );
};

export default App;
