import axios from 'axios';

export class ConnectorApi {
    public static CreateConnectorConfiguration = (id: string, body: any) => {
        return axios.post('/api/connector/configuration/' + id, body);
    }
    public static UpdateConnectorConfiguration = (id: string, body: any) => {
        return axios.put('/api/connector/configuration/' + id, body);
    }
    public static DeleteConnectorConfiguration = (id: string) => {
        return axios.delete('/api/connector/configuration/' + id);
    }
}