import axios from 'axios';
import AccountDto from '../models/AccountDto';
import AlertDto from '../models/AlertDto';
import DeviceDto from '../models/DeviceDto';
import MonitoringDeviceDto from '../models/MonitoringDeviceDto';
import NotificationStatusDto from '../models/NotificationStatusDto';

export class TeamViewerApi {
    public static getUserAccount = () => {
        return axios.get<AccountDto>('/api/teamviewer/account');
    }
    public static getDevices = () => {
        return axios.get<MonitoringDeviceDto[]>('/api/teamviewer/devices');
    }
    public static getDeviceInformation = (deviceId: string) => {
        return axios.get<DeviceDto>(`/api/teamviewer/device/${deviceId}/information`);
    }
    public static getAlertById = (id: string) => {
        return axios.get<AlertDto>(`/api/teamviewer/alert/${id}`);
    }
    public static getNotificationsStatus = (aadUserId: string) => {
        return axios.get<NotificationStatusDto>(`/api/teamviewer/notificationsstatus/${aadUserId}`);
    }
    public static updateNotificationsStatus = (aadUserId: string, enabled: boolean) => {
        return axios.post<boolean>(`/api/teamviewer/notificationsstatus/${aadUserId}`, {
            enabled
        } as NotificationStatusDto);
    }
}